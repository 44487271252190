import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';

import Globals from 'components/Theme'
import Loadable from "@loadable/component"

// Helpers
import { getSrc } from 'components/Helpers/ImageHelper';

// Image Slider (Dynamic Load)
const ImageSlider = Loadable(() => import('react-image-comparison-slider'))

const InternalComparisonContainer = styled(Container)`
  justify-content:center;
  align-items:center;
  position:relative;
  ${media.between("tablet", "desktop")`
    
  `}
  ${media.lessThan("tablet")`
  
  `}
`;

const ImageSliderCard = styled(Card)`
  justify-content:center;
  align-items:center;
  position:relative;
  
  div {
    
  }

  img {
    max-width:none;
  }
  ${media.lessThan("tablet")`
    width:100%;
    height:40rem;
  `}
`;

const ImageSliderWrapper = styled.div`
  width:70rem;
  height:45rem;
  ${media.lessThan("tablet")`
    width:35rem;
    height:20rem;
  `}
`;



class GlobalUnderTheHood extends Base {

  render() {
    const { img1Fluid, img2Fluid } = this.props.data;

    //const Data = this.Data
    

    return (
      <InternalComparisonContainer gutterBottom>
        <ImageSliderCard>
          <ImageSliderWrapper>
            <ImageSlider
                image1={getSrc(img2Fluid)}
                image2={getSrc(img1Fluid)}
                sliderColor={Globals.brandPrimaryAlt}
                handleBackgroundColor={Globals.brandPrimaryAlt}
                handleColor={Globals.brandPrimary}
            />
          </ImageSliderWrapper>
        </ImageSliderCard>
      </InternalComparisonContainer>
    )
  }
}

export default GlobalUnderTheHood


