import React from "react";
import { Base } from '.';

import { h1  } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import { GatsbyImage } from "gatsby-plugin-image"


const HeroContainer = styled.div `
  height:70vh;
  position:relative;
  ${media.lessThan("tablet")`
    height:50vh;
  `}
`;

const HeroCopy = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  height:100%;
  padding:2rem;
`;

const HeroAsset = styled(GatsbyImage)
`
  position:absolute !important;
  width:100%;
  height:100%;
  z-index:-99;
  object-fit:cover;
`;

const HeroHeadline = styled(h1)`
  color:#fff;
`;


class GlobalSimpleGatsbyImage extends Base {

    render() {
        const { img1Fluid, h1  } = this.props.data;
        const Data = this.Data
        const imageAsset = img1Fluid.gatsbyImageData
        
        return ( 
          <HeroContainer>
            <Data label={`img1`}>
              <HeroAsset image={imageAsset}/>
            </Data>
            <HeroCopy>
              <HeroHeadline><Data label={`h1`}>{ h1 }</Data></HeroHeadline>
            </HeroCopy>
          </HeroContainer>
        )
    }
}



export default GlobalSimpleGatsbyImage