import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2, h3 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Variant from 'components/Shopify/Variant';
// import Price from 'components/Theme/Price';

const HeroContainer = styled(Container)`
  height:80vh;
`;

const HeroCard = styled(Card)`
  justify-content:center;
  align-items:flex-start;
  flex-direction:column;
  overflow:hidden;
  position:relative;
  background-position: bottom center;
  margin-bottom:0;
  ${media.lessThan("tablet")`
    justify-content:center;
    align-items:center;
    &::before,
    &::after {
      filter: brightness(0.6) 
    }
  `}
`;


const HeroAsset = styled(GatsbyImage)`
  position:absolute !important;
  width:100%;
  height:100%;
  z-index:-99;
  object-fit:cover;
  border-radius:0.5rem;
  ${media.lessThan("tablet")`
    display:none;
  `};
`;

const HeroAssetMobile = styled(GatsbyImage)`
  position:absolute !important;
  width:100%;
  height:100%;
  z-index:-99;
  object-fit:cover;
  border-radius:0.5rem;
  ${media.greaterThan("tablet")`
    display:none;
  `};
`;

const HeroCopy = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  top:-15rem;
  text-align:center;
  width:100%;
  align-items:center;
  ${media.lessThan("tablet")`
    padding-right:0;
    padding-left:0;
    max-width:90%;
    margin-left:0;
    text-align:center;
    align-items:center;
    height:100%;
    top:3rem;
    justify-content:space-between;
  `};
`;

const HeroHeadlineWrapper = styled.div`
  display:flex;
  flex-direction: column;
  ${media.lessThan("tablet")`
    margin-top:5vh;
    justify-content:center;
    align-items:center;
  `};
`;

const HeroHeadline = styled(h1)`
  color:#fff;
  text-shadow:0 0rem 1rem #353535;
  margin:0 0 2.5rem 0;
  font-size:8rem;
  ${media.greaterThan("tablet")`
    line-height:6.4rem;
  `};
  ${media.lessThan("tablet")`
    line-height:5rem;
    font-size:4.8rem;
  `};
`;

const HeroSubheadline = styled(h2)`
  color:#fff;
  ${media.lessThan("tablet")`
    font-size:1.75rem;
  `};
`;

const BuyNowButton = styled(Button)`
  color:#000;
  ${media.lessThan("tablet")`
    margin-bottom:15vh;
  `};
`;

const Purchasing = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:2rem;
`;


class GlobalHeroTopCopy extends Base {

  render() {
    const { h1, h2, h3, img1Fluid, img2Fluid, shopifyId } = this.props.data;
    const heroImage = img1Fluid.gatsbyImageData
    const mobileImage = img2Fluid.gatsbyImageData
    const shopify = this.props.shopify;
    const Data = this.Data;

    return (
      <HeroContainer> 
        <HeroCard neumorphic>
          <Data label={`img1`}><HeroAsset image={heroImage}/></Data>
          <Data label={`img1`}><HeroAssetMobile image={mobileImage}/></Data>
          <HeroCopy>
            <HeroHeadlineWrapper>
              <HeroHeadline study={`h1`}>
                <Data label={`h1`}>{h1}</Data>
              </HeroHeadline>
              <HeroSubheadline study={`h2`}>
                <Data label={`h2`}>{h2}</Data>
              </HeroSubheadline>
            </HeroHeadlineWrapper>

            <Purchasing>
              <Variant shopify={shopify} variantId={shopifyId}>
                {variant => (
                  <>
                    <BuyNowButton to={`/products/${variant.product.handle}`}>Shop Now</BuyNowButton>
                  </>
                )}
              </Variant>
            </Purchasing>
          </HeroCopy>
        </HeroCard>
      </HeroContainer>
    )
  }
}



export default GlobalHeroTopCopy
