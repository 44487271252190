import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2 } from 'components/Theme/Copy';
import { Container } from 'components/Theme/Grid';
import FadeUp from "components/Theme/FadeUp";
import Button from 'components/Theme/Button';

const TextContainer = styled(Container)`
  background-color:${p => p.inverted ? p.theme.white : 'transparent'}
`;

const Copy = styled.div`
  text-align:center;
  justify-content: center;
  align-items: center;
  flex-direction:row;
  padding:6rem 0 4rem 0;
  width:100%;
`;

const Headline = styled(h1)`
  font-size:5rem;
  text-align:center;
  width:100%;
  color:${p => p.inverted ? p.theme.black : p.theme.white} !important;
  ${media.lessThan("tablet")`
    font-size:4rem;
  `};
`;

class GlobalTitleCard extends Base {

  render() {
    const { h1, variable1 } = this.props.data;
    const Data = this.Data;
    let inverted = variable1 == 'inverted' ? true : false;

    return (
      <TextContainer inverted={inverted}> 
        <Copy>
          <Headline inverted={inverted}><FadeUp>{ h1 }</FadeUp></Headline>
        </Copy>
      </TextContainer>
    )
  }
}



export default GlobalTitleCard
