import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { GatsbyImage } from "gatsby-plugin-image"
import { h1, h2, body } from 'components/Theme/Copy';
import { Container, Card  } from 'components/Theme/Grid';

import Variant from 'components/Shopify/Variant';
import Price from 'components/Theme/Price';
import Button from 'components/Theme/Button';
import SplitOutlineHeading from 'components/Theme/SplitOutlineHeading';
import SpecsAndFeatures from 'components/Page/Product/SpecsAndFeatures';

const ProductsContainer = styled(Container)`
  
`;

const ProductsCard = styled(Card)`
  flex-direction:column;
  align-items:center;
  justify-content: center;
  width:70%;
`;

const FeaturesCard = styled(ProductsCard)`
  width:30%;
  position:relative;
`;


const ProductWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
  width:100%;
  ${media.lessThan("tablet")`
    padding:5rem 0;
    flex-direction:column;
    width:100%;
    display:flex;
    overflow:hidden;
  `}
`;

const ProductHeadline = styled(h1)`
  margin-bottom:0;
  color:${p => p.theme.cardTextColor};
`;

const ProductImage = styled(GatsbyImage)`
  width:100% !important;
  height:100% !important;
  ${media.lessThan("tablet")`
    width:30rem !important;
  `}
`;

const ProductShot = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  background-color:#CDC6BE;
  height:100%;
  width:100%;
  padding:3rem 0;
  ${media.lessThan("tablet")`
    width:100%;
    align-items:center;
  `}
`;

const ProductDetails = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:flex-start;
  color:${p => p.theme.white};
  width:100%;
  ${media.lessThan("tablet")`
    width:100%;
    align-items:flex-start;
    margin-bottom:5rem;
  `}
`;

const ProductDescription = styled(body)`
  width:80%;
  font-size:1.4rem;
  line-height:2rem;
`

const Copy = styled.div`
  display:flex;
  text-align:left;
  flex-direction:column;
  justify-content: center;
  align-items:flex-start;
  margin-top:3rem;
`;

const ProductTitle = styled(h1)`
  margin:0;
  color:${p => p.theme.cardTextColor};
  font-size:15rem;
  text-shadow:none;
  position:absolute;
  left:-10rem;
  top:0rem;
  ${media.lessThan("tablet")`
    margin:5rem 0 0 0;
  `}
`;

const FeaturesWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  ${media.lessThan("tablet")`
    padding:6rem 0;
  `}
`;

const Feature = styled(h2)`
  margin:0;
  padding:2rem;
  color:${p => p.theme.cardTextColor};
  text-shadow:none;
`;

const PriceLabel = styled(Price)`
  color:${p => p.theme.cardTextColor};
`;


class HomeProduct extends Base {

  render() {
    const { img1Fluid, shopifyId } = this.props.data;
    const shopify = this.props.shopify;
    const Data = this.Data;

    return (
      <ProductsContainer neumorphic fixedHeight={true}>
        <Variant variantId={shopifyId} shopify={shopify}>
          {variant => (
            <>
              <ProductsCard size={2} sm={1}>
                <ProductWrapper>
                  <ProductShot>
                    <ProductImage image={img1Fluid.gatsbyImageData} />
                  </ProductShot>
                </ProductWrapper>
              </ProductsCard>
              
              <FeaturesCard size={2} sm={1}>
                <ProductTitle><SplitOutlineHeading copy={variant.product.title} /></ProductTitle>
                <ProductDetails>
                  <Copy>
                    <PriceLabel price={variant.price} compareAtPrice={variant.compareAtPrice} />
                    <ProductDescription>{ variant.product.description }</ProductDescription>
                    <SpecsAndFeatures specs={ variant.product.specs } features={ variant.product.features } />
                    <Button to={'/products/' + variant.product.handle}>Buy Now</Button>
                  </Copy>
                </ProductDetails>
              </FeaturesCard>
            </>
          )}
        </Variant>
      </ProductsContainer>
    )
  }
}

export default HomeProduct
