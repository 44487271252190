import React from "react";
import { Base } from '.';

import { body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import FormTag from 'components/Page/Forms/Form';
import TextField from 'components/Page/Forms/TextField';
import TextAreaField from 'components/Page/Forms/TextAreaField';
import Button from 'components/Theme/Button'

const Container = styled.section`
  padding:0 10rem;
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content:center;
  ${media.lessThan("tablet")`
    padding:2.5rem 0 0 0;
  `}
`;

const Row = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
`;

const Col = styled.div`
  width:100%;
  padding-right:${p => p.first ? '1rem' : 'none'};
`;

const InnerFormContainer = styled.div`
  margin-top:2rem;
`;

const Controls = styled.div`
  display:flex;
  flex-direction: row;
`;


class SupportForm extends Base {
  render() {
    //const Data = this.Data

    return ( 
      <Container className={this.props.className}>
        <FormTag name="Support" successPage="/support-request-confirmation">
          <InnerFormContainer>
            <Row>
              <Col first={true}>
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="firstName"
                    required="required"
                    placeholder="First Name" />
              </Col>
              <Col>
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="lastName"
                    required="false"
                    placeholder="Last Name" />
              </Col>
            </Row>
            <Row>
              <Col first={true}>
                <TextField type="email"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="email"
                    required="required"
                    placeholder="Email Address" />
              </Col>
              <Col>
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="orderNumber"
                    required="false"
                    value={this.props.orderNumber}
                    placeholder="Order Number, example: C1234" />
              </Col>
            </Row>
            <TextAreaField
                type="textarea"
                name="message"
                placeholder="Message" />
            <Controls>
              <Button>Send</Button>
            </Controls>
          </InnerFormContainer>
        </FormTag>
      </Container>
    )
  }
}



export default SupportForm;