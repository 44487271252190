export const getSrc = (image) => {
  if (image) {
    if (image.gatsbyImageData) {
      let img = image.gatsbyImageData
      if (img.images) {
        let imgData = img.images
        if (imgData.fallback) {
          return imgData.fallback.src
        }
      }
    }
  }
}