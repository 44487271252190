import React from "react";
import { Base } from '.';

import { GatsbyImage } from "gatsby-plugin-image"
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2, body } from 'components/Theme/Copy';


const Container = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  max-width:${p => p.theme.containerWidth}rem;
  margin:5rem auto;
  ${media.between("tablet", "desktop")`
    padding:5rem 0;
  `};
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:0;
  `};
`;

const State = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  padding:5rem 0;
  &:nth-child(2) {
    padding:5rem 0 0 0;
  }
  &:last-child {
    border-top:0.1rem solid ${p => p.theme.brandPrimaryDarker};
  }
  ${media.lessThan("tablet")`
    flex-direction:column;
  `};
`;

const Image = styled(GatsbyImage)`
  margin-left:-4.5rem;
  width:25rem !important;
  height:25rem !important;
`;

const Instruction = styled(body)`
  font-size:1.6rem;
  width:50%;
  ${media.lessThan("tablet")`
    width:100%;
    text-align:center;
  `};
`;

const Illustration = styled.div`

`

const Headline = styled(h1)`
  font-size:2.6rem;
  text-align:left;
  margin:0;
  ${media.lessThan("tablet")`
    margin-bottom:0;
  `};
`

const Subheadline = styled(h2)`
  color:#000;
  text-shadow:none;
  text-align:center;
`

// Carbon Makes it Easy
class DocIgnitionIllustration extends Base {

  render() {
    const { h1, h2, h3, h4, body1, body2, body3, img1Fixed, img2Fixed, img3Fixed } = this.props.data;
    const Data = this.Data;
   
    const img1 = img1Fixed.gatsbyImageData
    const img2 = img2Fixed.gatsbyImageData
    const img3 = img3Fixed.gatsbyImageData

    return (
      <Container>
        <Headline>
          <Data label={`h1`}>{ h1 }</Data>
        </Headline>

        <State>
          <Illustration>
            <Data label={`img1`}>
              <Image image={img1} />
            </Data>

            <Subheadline>
              <Data label={`h2`}>{ h2 }</Data>
            </Subheadline>
          </Illustration>

          <Instruction>
            <Data label={`body1`}>{ ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }</Data>
          </Instruction>
        </State>

        <State>
          <Illustration>
            <Data label={`img2`}>
              <Image image={img2} />
            </Data>

            <Subheadline>
              <Data label={`h3`}>{ h3 }</Data>
            </Subheadline>
          </Illustration>

          <Instruction>
            <Data label={`body2`}>{ ReactHtmlParser(body2 && body2.childMarkdownRemark.html) }</Data>
          </Instruction>
        </State>

        <State>
          <Illustration>
            <Data label={`img3`}>
              <Image image={img3} />
            </Data>

            <Subheadline>
              <Data label={`h4`}>{ h4 }</Data>
            </Subheadline>
          </Illustration>

          <Instruction>
            <Data label={`body3`}>{ ReactHtmlParser(body3 && body3.childMarkdownRemark.html) }</Data>
          </Instruction>
        </State>
      </Container>
    )
  }
}

export default DocIgnitionIllustration
