import React from "react";
import { Base } from 'components/Blocks';

// Helpers
import ReactHtmlParser from 'react-html-parser'

// Styles
import { h4, body  } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';


// Components
import YouTube from 'react-youtube';
import { GatsbyImage } from "gatsby-plugin-image"


const BodyContainer = styled(Container)`
  padding:12rem;
  background-color:${p => p.theme.cardBgColor};
  ${media.lessThan("tablet")`
    padding:12rem 4rem;
  `};
`;

const Headline = styled(h4)`
  font-size:3rem;
  text-transform: uppercase;
  margin-bottom:2rem;
  display:flex;
`;

const BodyCard = styled(Card)`
  flex-direction:column;

  ${media.lessThan("tablet")`
    align-items:center;
    text-align:center;
  `};
`;

const LearnMore = styled.a`
  color:${p => p.theme.brandPrimaryTextColor};
  font-size:1.8rem;
  margin-top:5rem;
  display:inline-block;
`;

const MadeInUSCopy = styled(body)`
  color:${p => p.theme.cardTextColor};
  margin:2rem 0 0 0;
  font-size:2.6rem;
`;

const ProluxeCopy = styled(body)`
  color:${p => p.theme.cardTextColor};
  margin:2rem 0 0 0;
  font-size:2.6rem;
`;

const Flag = styled(GatsbyImage)`
  width:5rem;
  height:2.55rem;
  margin-right:0.8rem;
  position:relative;
  top:0.7rem;
`;

const YouTubeWrapper = styled.div`
  
  .youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

class StoryFactory extends Base {

    _onReady = (e) => {
      // access to player in all event handlers via event.target
      e.target.pauseVideo();
    }

    render() {
        const { h1, h2, h3, img1Fluid} = this.props.data;
        const Data = this.Data;

        const img1 = img1Fluid.gatsbyImageData

        return ( 
          <BodyContainer neumorphic>
            <BodyCard>
              <Headline><Flag image={img1} /> Made in USA</Headline>
              <MadeInUSCopy>
                <Data label={`h1`}>{ h1 }</Data>
              </MadeInUSCopy>

              <ProluxeCopy>
                <Data label={`h2`}>{ h2 }</Data>
              </ProluxeCopy>

              <div>
                <LearnMore href="https://proluxe.com" target="_blank">{h3} →</LearnMore>
              </div>

            </BodyCard>
          </BodyContainer>
        )
    }
}

export default StoryFactory