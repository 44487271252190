import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h3, body } from 'components/Theme/Copy';


const InfoBar = styled.div `
  padding:6.5rem 4.5rem;
  display:flex;
  border-radius:0.5rem;
  justify-content:space-evenly;
  background-color:#CDC6BE;
  font-size:1.4rem;
  
  
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:4.5rem 4rem 0 4rem;
  `}
`
const InfoBarItem = styled.div`
  text-align:center;
  border-right:0.1rem solid #545454;
  padding:0 3rem;
  flex-basis: 100%;
  height:30rem;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  ${media.lessThan("tablet")`
    padding-bottom:4.5rem;
    width:100%;
    border-right:0;
  `}

  &:last-child {
    border-right:0;
  }
`;

const BenefitsHeadline = styled(h3)`
  color:${p => p.theme.black};
  display:block;
  margin:1.5rem 0;
  padding:0;
`;

const BenefitsSubheadline = styled(body)`
  margin-bottom:0.5rem;
  color:${p => p.theme.black};
  font-size:1.8rem;
  line-height:2rem;
  ${media.lessThan("tablet")`
    font-size:2rem;
    line-height:2.2rem;
  `}
`;

class GlobalBenefitsAlt extends Base {

    render() {
        const { h1, h2, h3, h4, h5, h6 } = this.props.data;
        const Data = this.Data;
        
        return ( 
          <div>
            <InfoBar>
              <InfoBarItem>
                <BenefitsHeadline><Data label={`h2`}>{h1}</Data></BenefitsHeadline>
                <BenefitsSubheadline><Data label={`h3`}>{h2}</Data></BenefitsSubheadline>
              </InfoBarItem>
              <InfoBarItem>
                <BenefitsHeadline><Data label={`h3`}>{h3}</Data></BenefitsHeadline>
                <BenefitsSubheadline><Data label={`h4`}>{h4}</Data></BenefitsSubheadline>
              </InfoBarItem>
              <InfoBarItem>
                <BenefitsHeadline><Data label={`h5`}>{h5}</Data></BenefitsHeadline>
                <BenefitsSubheadline><Data label={`h6`}>{h6}</Data></BenefitsSubheadline>
              </InfoBarItem>
            </InfoBar>
          </div>
        )
    }
}

export default GlobalBenefitsAlt