import React from 'react'
import { Base } from '.';
import { StaticQuery, graphql } from "gatsby"

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card  } from 'components/Theme/Grid';
import { h3 } from 'components/Theme/Copy';

import RecipeCard  from 'components/Page/Recipe/RecipeCard';

const RecipesContainer = styled(Container)`
  ${media.lessThan("tablet")`
    flex-direction:row;
  `};
`;

const HeadingCard = styled(Card)`
  background-color:${p => p.theme.cardBgColorDark};
  padding:${p => p.theme.boxPadding}rem;
  display:flex;
  justify-content: center;
  align-items:center;
  position:relative;
  
  ${media.lessThan("tablet")`
    background-color:transparent;
    box-shadow:none;
    padding:1.5rem 0 3rem 0;
    &:last-child {
      display:none;
    }
  `};
`;

const Heading = styled(h3)`
  color:#fff;
  font-size:2rem;
  ${media.lessThan("tablet")`
    text-align:center;
    color:${p => p.theme.cardTextColor};
  `};
`;



class GlobalGettingStartedRecipes extends Base {
  render() {
    const { h1 } = this.props.data
    const Data = this.Data

    return (
      <StaticQuery
          query={graphql`
            query GlobalGettingStartedRecipes {
              allContentfulRecipe(filter: { gettingStarted: { eq: true } }) {
                edges {
                  node {
                    tags
                    handle
                    gettingStarted
                    heroTitle
                    difficulty
                    diet
                    heroDescription
                    directionsTotalTime
                    heroAsset {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            let recipes = data.allContentfulRecipe.edges

            return (
              <RecipesContainer>
                {/* <Heading><Data label={`h1`}>{h1}</Data></Heading> */}\
                <HeadingCard neumorphic size={3} sm={1}>
                  <Heading><Data label={`h1`}>{h1}</Data></Heading>
                </HeadingCard>
                {recipes.map((recipe, i) => {
                  return <RecipeCard data={ recipe.node }
                                     key={`recipe_${i}`}
                                     size={3}
                                     sm={1} />
                })}
              </RecipesContainer>
            )
          }} />
    )
  }
}



export default GlobalGettingStartedRecipes