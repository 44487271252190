import React from "react";
import { Base } from '.';

import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h4, h5, body } from 'components/Theme/Copy';
import { Container, Card  } from 'components/Theme/Grid';

const DIYContainer = styled(Container)`
  justify-content:center;
  ${media.lessThan("tablet")`
    padding:0;
  `};
`;

const HeadlineCard = styled(Card)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:${p => p.theme.cardBgColor};

  ${media.lessThan("tablet")`
    padding:0 1.5rem;
  `};
`;

const AssetCard = styled(Card)`
  flex-direction: row;
  flex-wrap:wrap;
`;

const Copy = styled.div`
  padding:8rem;

  ${media.lessThan("tablet")`
    padding:2rem;
  `};
`;

const Headline = styled(h4)`
  margin-bottom:3rem;
  text-align:left;
  color:${p => p.theme.cardTextColor};
  font-size:${p => p.theme.subHeadingFontSize}rem;
  ${media.lessThan("tablet")`
    margin-top:5rem;
    text-align:center;
  `};
`;


const Subheadline = styled(h5)`
  text-align:left;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    max-width:40rem;
    margin:0 auto 5rem auto;
    text-align:center;
  `};
`;

const Image = styled(GatsbyImage)`
  border-radius:0.5rem;
  width:100% !important;
  height:100%;
  ${media.lessThan("tablet")`
    width:100% !important;
  `};
`;

const AssetContainer = styled(Container)`
  width:100%;

  ${media.lessThan("tablet")`
    flex-direction:row;
  `};
`;

const Asset = styled(Card)`
  height:calc(50% - (1.5rem / 2));
`;

// Carbon Makes it Easy
class HomeDIY extends Base {

  render() {
    const { h1, h2, img1Fluid, img2Fluid, img3Fluid, img4Fluid } = this.props.data;
    const Data = this.Data;

    const img1 = img1Fluid.gatsbyImageData
    const img2 = img2Fluid.gatsbyImageData
    const img3 = img3Fluid.gatsbyImageData
    const img4 = img4Fluid.gatsbyImageData
   
    return (
      <DIYContainer gutterBottom>
        <HeadlineCard neumorphic fixedHeight size={2} sm={1}>
          <Copy firstCol={true}>
            <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
            <Subheadline><Data label={`h2`}>{ h2 }</Data></Subheadline>
          </Copy>
        </HeadlineCard>
        <AssetCard fixedHeight size={2} sm={1}>
          <AssetContainer fixedHeight>
            <Asset neumorphic size={2} sm={2}>
              <Data label={`img1`}>
                <Image image={img1} />
              </Data>
            </Asset>
            <Asset neumorphic size={2} sm={2}>
              <Data label={`img2`}>
                <Image image={img2} />
              </Data>
            </Asset>
            <Asset neumorphic size={2} sm={2}>
              <Data label={`img3`}>
                <Image image={img3} />
              </Data>
            </Asset>
            <Asset neumorphic size={2} sm={2}>
              <Data label={`img4`}>
                <Image image={img4} />
              </Data>
            </Asset>
          </AssetContainer>
        </AssetCard>
        
      </DIYContainer>
    )
  }
}

export default HomeDIY
