import React from "react";
import { Base } from '.';

import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card  } from 'components/Theme/Grid';

const ConfirmCard = styled(Card)`
  position:relative;
  flex-direction:column;
  align-items:center;
`;

const Bg = styled(GatsbyImage)`
  object-fit:cover;
  width:100%;
  height:100%;
  color:#fff;
  position:absolute !important;
  z-index:-1;
  border-radius:0.5rem;
`;

const Heading = styled.div`
  font-size:5.6rem;
  line-height:4.8rem;
  color:#fff;
  font-weight:200;
  padding:10rem 0 2rem 0;
  ${media.lessThan("tablet")`
    font-size:3rem;
    line-height:3.2rem;
  `}
`;

const Subheading = styled.div`
  color:#fff;
  line-height:2.4rem;
  font-size:2rem;
  font-weight:200;
`;

class GlobalEmailSignupConfirmation extends Base {
    render() {
      const { img1Fluid, h1, h2 } = this.props.data;
      const Data = this.Data;

      const bgImage = img1Fluid.gatsbyImageData

      return ( 
        <Container fixedHeight>
          <ConfirmCard neumorphic>
            <Data label={`img1`}>
              <Bg image={bgImage} />
            </Data>
            <Heading><Data label={`h1`}>{ h1 }</Data></Heading>
            <Subheading><Data label={`h2`}>{ h2 }</Data></Subheading>
          </ConfirmCard>
        </Container>
    )
    }
}

export default GlobalEmailSignupConfirmation
