import React, { useState } from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h3 } from 'components/Theme/Copy';
import { Container, CardStyle  } from 'components/Theme/Grid';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';

import { GatsbyImage } from "gatsby-plugin-image"
import Variant from 'components/Shopify/Variant';
import Button from 'components/Theme/Button'

const ProductsContainer = styled(Container)`
  overflow:hidden;
  flex-direction:column;
  background-color:${p => p.theme.white};
  padding:${p => p.theme.boxPadding}rem;
  ${media.lessThan("tablet")`
    flex-wrap:nowrap;
    padding:6rem 3rem;
  `}
`;

const ProductAccessoryContainer = styled(Container)`
  ${media.lessThan("tablet")`
    flex-direction:row;
  `}
`;


const BlockHeadline = styled(h3)`
  width:100%;
  text-align:center;
  font-weight:bold;
  margin-bottom:3rem;
  color:${p => p.theme.black};
`

const ProductsCard = styled(animated.div)`
  ${CardStyle};
  flex-direction:column;
  align-items:center;
  justify-content: flex-end;
  position:relative;
  ${media.lessThan("tablet")`
    flex-direction:row;
  `}
`;

const FeaturesCard = styled(ProductsCard)`
  
`;


const ProductWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
  
  padding:5rem 0;
  width:100%;
  background-color:rgba(0,0,0,0.6);
  ${media.lessThan("tablet")`
    padding:5rem 0;
    flex-direction:column;
    width:100%;
    height:100%;
    display:flex;
    overflow:hidden;
    justify-content:center;
  `}
`;

const ProductHeadline = styled(h1)`
  margin-bottom:1.5rem;
  color:#fff;

  ${media.lessThan("tablet")`
    font-size:5rem;
  `};
`;

const ProductImage = styled(GatsbyImage)`
  position:absolute !important;
  width:100%;
  height:100%;
  z-index:-99;
  object-fit:cover;
`;

const ProductDetails = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Copy = styled.div`
  display:flex;
  text-align:center;
  flex-direction:column;
  justify-content: center;
  align-items:center;
`;


const HomeCommercial = ({data, shopify}) => {
  const { h1, h2, img1Fluid, img2Fluid, shopifyId } = data;
  const [isScrolled, setIsScrolled] = useState(false);

  const animationLeft = useSpring({
    opacity: isScrolled ? 1 : 0,
    transform: isScrolled ? "translateX(0px)" : "translateX(-50px)"
  });

  const animationRight = useSpring({
    opacity: isScrolled ? 1 : 0,
    transform: isScrolled ? "translateX(0px)" : "translateX(50px)"
  });

  const img1 = img1Fluid.gatsbyImageData
  const img2 = img2Fluid.gatsbyImageData

  return (
    <>
      <Waypoint onEnter={() => setIsScrolled(true) } />
      
      <ProductsContainer>
        <BlockHeadline>Our Products</BlockHeadline>

        <ProductAccessoryContainer fixedHeight={true}>
          <ProductsCard neumorphic size={2} sm={1} style={animationLeft}>
            <ProductImage image={img1} />

            <Variant variantId={shopifyId} shopify={shopify}>
              {variant => (
                <ProductWrapper>
                  <ProductHeadline>{h1}</ProductHeadline>
                  <ProductDetails>
                    <Copy>
                      <Button to={'/outdoor'}>Shop Now</Button>
                    </Copy>
                  </ProductDetails>
                </ProductWrapper>
              )}
            </Variant>
          </ProductsCard>


          <FeaturesCard neumorphic size={2} sm={1} style={animationRight}>
            <ProductImage image={img2} />

            <ProductWrapper>
              <ProductHeadline>{h2}</ProductHeadline>
              <ProductDetails>
                <Copy>
                  <Button to={'/commercial'}>Learn More</Button>
                </Copy>
              </ProductDetails>
            </ProductWrapper>
          </FeaturesCard>
        </ProductAccessoryContainer>
      </ProductsContainer>
    </>
  )
}

export default HomeCommercial
