import React from "react";
import { Base } from '.';

// Styles
import { Container, Card } from 'components/Theme/Grid';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4, h5 } from 'components/Theme/Copy';

// Components
import { GatsbyImage } from "gatsby-plugin-image"

const CarbonCraftedContainer = styled(Container)`
  margin-top:${p => p.theme.boxGutterY}rem;
`;

const CarbonCraftedCard = styled(Card)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
`;

const Headline = styled(h4)`
  text-align:center;
  margin-bottom:1.5rem;
`;

const Subheadline = styled(h5)`
  text-transform:uppercase;
  text-align:center;
`;

const Images = styled.div`
  display:flex;
  flex-direction: row;
  margin-top:6rem;
  flex-wrap: wrap;
  width:100%;
`;

const ImageWrapper = styled.div`
  margin-right:1.5rem;
  margin-bottom:1.5rem;
  flex:1 1 calc(33.333% - 3rem);
  

  &:nth-child(3n) { 
    margin-right:0;
  }
`;

const Image = styled(GatsbyImage)`
  border-radius:0.5rem;
  height:20rem;
`;

class GlobalFollowUs extends Base {

  render() {
    const { h1, h2, imageGalleryFluid } = this.props.data;
    
    const Data = this.Data;

    return (
      <CarbonCraftedContainer> 
        <CarbonCraftedCard>
          <Headline><Data label={`h1`}>{h1}</Data></Headline>
          <Subheadline><Data label={`h2`}>{h2}</Data></Subheadline>

          {/* <Data label={`imageGallery`}></Data> */}
          <Images>            
            {imageGalleryFluid.map((image, i) => {
              return (
                <ImageWrapper key={`image_${i}`}>
                  <Image image={image.gatsbyImageData} />
                </ImageWrapper>
              )
            })}
          </Images>
        </CarbonCraftedCard>
      </CarbonCraftedContainer>
    )
  }
}



export default GlobalFollowUs;
