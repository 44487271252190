import React from "react";
import { Base } from '.';
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { GatsbyImage } from "gatsby-plugin-image"
import { h4, h5 } from 'components/Theme/Copy';

const Container = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  padding:0;
  position:relative;
  justify-content:space-around;
  ${media.lessThan("tablet")`
    margin:0 auto;
    flex-direction:column;
  `};
`;

const Asset = styled.div`
  width:50%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  padding:1.5rem;
  position:relative;
  ${media.lessThan("tablet")`
    width:100%;
  `};
`;

const Image = styled(GatsbyImage)`
  position:relative;
  border-radius:0.5rem;
  width:100%;
  box-shadow:0 0 3.5rem 1rem rgba(0,0,0,0.22);
`;

const Overlay = styled.div`
  position:absolute;
  bottom:1.5rem;
  left:1.5rem;
  z-index:99;
  width:calc(100% - 3rem);
  text-align:center;
  padding:1.5rem;
  background-color:rgba(0,0,0,0.6);
`;

const Headline = styled(h4)`
  text-align:center;
  background-color:transparent !important;
  
  color:${p => p.theme.cardTextColor};
  margin-bottom:1.5rem;
`;

const Subheadline = styled(h5)`
  color:${p => p.theme.cardTextColor};
  font-size:2rem;
  text-align:center;
`;

class GlobalDualFeature extends Base {


  render() {
    const { img1Fluid, img2Fluid, h1, h2, body1, body2 } = this.props.data;
    const Data = this.Data;
    const imageLeft = img1Fluid.gatsbyImageData
    const imageCenter = img2Fluid.gatsbyImageData
    
   
    return (
      <Container>
        <Asset>
          <Data label={`img1`}>
            <Image image={imageLeft} />
            <Overlay><Data>
              <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
              <Subheadline>{ ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }</Subheadline>
              </Data></Overlay>
          </Data>
        </Asset>
        <Asset>
          <Data label={`img2`}>
            <Image image={imageCenter} />
            <Overlay><Data>
            <Headline><Data label={`h2`}>{ h2 }</Data></Headline>
            <Subheadline>{ ReactHtmlParser(body2 && body2.childMarkdownRemark.html) }</Subheadline>
            </Data></Overlay>
          </Data>
        </Asset>
        
      </Container>
    )
  }
}

export default GlobalDualFeature
