import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h4, body } from 'components/Theme/Copy';
import { Container } from 'components/Theme/Grid';
import FadeUp from 'components/Theme/FadeUp';

import { IoIosStar } from "react-icons/io";

const TestimonialsWrapper = styled(Container)`
  margin-bottom:16rem;
  align-items:center;
  justify-content: center;
`;

const TestimonialsContainer = styled.div`
  position: relative;
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content: center;
  width:90%;
  margin:0 auto;
  padding-top:8rem;
  ${media.lessThan("tablet")`
    padding:8rem 3rem;
    flex-direction:column;
    align-items:center;
    padding-top:4rem;
  `}
`;

const HeadlineCol = styled.div`
  min-width:35rem;
  align-self:center;
  ${media.lessThan("tablet")`
    padding:2.5rem;
  `}
`;

const Headline = styled(h4)`
  color:${p => p.theme.cardTextColor};  
  
  ${media.lessThan("tablet")`
    text-align:center;
  `};
`;

const TestimonialWrapper = styled(FadeUp)`
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:0 1.5rem;
  ${media.lessThan("tablet")`
    margin-top:3rem;
    justify-content: center;
    align-items: center;
    width:100%;
  `};
`;

const Stars = styled.div`
  margin-bottom:2rem;
  ${media.lessThan("tablet")`
    display:flex;
    justify-content:center;
  `}
`;

const Star = styled(IoIosStar)`
  color:${p => p.theme.brandPrimaryTextColor};
  font-size:2rem;
`;

const Testimonial = styled(body)`
  color:${p => p.theme.cardTextColor};  
  font-size:1.8rem;
  line-height:1.4em;
  text-align:center;
`;

const FiveStars = (props) => (
  <Stars>
    <Star /> <Star /> <Star /> <Star /> <Star />
  </Stars>
)

class HomeTestimonials extends Base {
  render() {    
    const { h1, h2, h3, h4, h5, h6 } = this.props.data
    const Data = this.Data

    return (
      <TestimonialsWrapper gutterBottom>
        <HeadlineCol>
          <FadeUp>
            <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
          </FadeUp>
        </HeadlineCol>
        <TestimonialsContainer>
          <TestimonialWrapper>
            <FiveStars />
            <Testimonial><Data label={`h2`}>"{ h2 }"</Data></Testimonial>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <FiveStars />
            <Testimonial><Data label={`h3`}>"{ h3 }"</Data></Testimonial>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <FiveStars />
            <Testimonial><Data label={`h4`}>"{ h4 }"</Data></Testimonial>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <FiveStars />
            <Testimonial><Data label={`h5`}>"{ h5 }"</Data></Testimonial>
          </TestimonialWrapper>
          <TestimonialWrapper>
            <FiveStars />
            <Testimonial><Data label={`h6`}>"{ h6 }"</Data></Testimonial>
          </TestimonialWrapper>
        </TestimonialsContainer>
      </TestimonialsWrapper>
    )
  }
}

export default HomeTestimonials
