import React, { useState } from "react";
import { Base } from '.';

import { h1, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card  } from 'components/Theme/Grid';

import ReactHtmlParser from 'react-html-parser';
import FadeUp from 'components/Theme/FadeUp';
import { FiChevronRight } from "react-icons/fi";

const FAQContainer = styled(Container)`
  flex-direction:column;
  margin-top:14rem;
  ${media.lessThan("tablet")`
    margin-bottom:7rem;
  `}
`;

const FAQCard = styled(Card)`
  flex-direction:column;
  justify-content:center;
`;

const Headline = styled(h1)`
  text-align:center;
  font-size:5rem;
`;

const Articles = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:5rem 0;
`;

const Article = styled.div`
  width:80rem;
  margin-bottom:2rem;
  padding-bottom:2rem;
  border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};

  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Header = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  cursor:pointer;
`;

const ArticleLink = styled.div`
  font-size:1.8rem;
  font-weight:300;
  display:block;
  color:${p => p.theme.cardTextColor};
`;

const ArticleBody = styled(body)`
  margin:4rem 0 1.5rem 0;
  font-size:1.5rem;
  line-height:2rem;
  padding:0 1.5rem;
  display:${p => p.showBody ? 'block' : 'none'};
`;

const Arrow = styled(FiChevronRight)`
  color:${p => p.theme.cardTextColor};
  transition:all 0.3s;
  font-size:1.8rem;
  ${p => p.showBody ? 'transform: rotate(90deg);' : null};
`;

const FAQ = ({q, a}) => {
  const [show, toggleShow] = useState(false);

  return (
    <Article>
      <Header onClick={() => toggleShow(!show)}>
        <ArticleLink>{q}</ArticleLink>
        <Arrow showBody={show} />
      </Header>
      <ArticleBody showBody={show}>{ ReactHtmlParser(a && a.childMarkdownRemark && a.childMarkdownRemark.html) }</ArticleBody>
    </Article>
  )
}

const ProductFAQ = ({data}) => {
  return ( 
    <FAQContainer gutterBottom gutterTop>
      <FAQCard>
        <FadeUp>
          <Headline>FAQs</Headline>
        </FadeUp>
        <FadeUp>
        <Articles>
          {[1,2,3,4,5].map((i) => {
            return <FAQ q={ data[`h`+i] } a={ data[`body`+i] } />
          })}
        </Articles>
        </FadeUp>
      </FAQCard>
    </FAQContainer>
  )
}



export default ProductFAQ;