import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button'
import Video from 'components/Theme/Video'; 


const UnderfloorContainer = styled(Container)`
  
`;

const UnderfloorCard = styled(Card)`
  justify-content:center;
  flex-direction:column;
  overflow:hidden;
  position:relative;
  background-position: bottom center;

  ${media.lessThan("tablet")`
    padding:0 3rem;
  `};
`;

const UnderfloorCopy = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  
  text-align:center;
  margin:0 auto;
  ${media.lessThan("tablet")`
    width:100%;
    padding:0;
    max-width:40rem;
  `};
`;

const UnderfloorHeadline = styled(h1)`
  color:#fff;
  text-shadow:0 0.1rem 3rem #39280B;
  margin:0 0 2.5rem 0;
`;

const UnderfloorSubheadline = styled(h2)`
  max-width:80rem;
  color:#fff;
`;

const LearnMoreButton = styled(Button)`
  color:#000;
`;

const Vid = styled(Video)`
  filter: brightness(1.1);
`;

class GlobalUnderfloorVideo extends Base {

  render() {
    const { h1, h2, vid1 } = this.props.data;
    const Data = this.Data

    return (
      <UnderfloorContainer fixedHeight>
        <UnderfloorCard neumorphic>
          <Data label={`vid1`}>
            <Vid src={vid1.file.url} />
          </Data>
          
          <UnderfloorCopy>
            <UnderfloorHeadline><Data label={`h1`}>{h1}</Data></UnderfloorHeadline>
            <UnderfloorSubheadline><Data label={`h2`}>{h2}</Data></UnderfloorSubheadline>
            <LearnMoreButton to={"/underbrick-burner"}>Learn More</LearnMoreButton>
          </UnderfloorCopy>
        </UnderfloorCard>
      </UnderfloorContainer>
    )
  }
}



export default GlobalUnderfloorVideo
