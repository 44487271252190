import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import FadeUp from "components/Theme/FadeUp";

import { GatsbyImage } from "gatsby-plugin-image";
import Button from 'components/Theme/Button';

const TextImageContainer = styled(Container)`
  
`;

const TextImageCard = styled(Card)`
  justify-content:center;
  align-items:center;
  height:70rem;
  flex-direction:${p => p.flexDirection || 'row'};
  ${media.lessThan("tablet")`
    flex-direction:column;
    height:auto !important;
  `}
`;

const Copy = styled.div`
  padding:${p => p.theme.boxPadding}rem;
  width:50%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lessThan("tablet")`
    width:80%;
    padding:${p => p.theme.boxPadding*2}rem 0;
  `}
`;

const Headline = styled(h1)`
  text-align:center;
  background-color:transparent !important;
  width:45rem;
  color:${p => p.theme.cardTextColor};
  margin-bottom:3rem;
  font-size:8rem;
  line-height:8rem;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Subheadline = styled(body)`
  color:${p => p.theme.cardTextColor};
  font-size:2rem;
  line-height:3rem;
  width:45rem;
  text-align:center;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Asset = styled.div`
  width:50%;
  height:100%;
  overflow:hidden;

  ${media.lessThan("tablet")`
    width:100%;
    height:40rem;
  `}
`;

const Image = styled(GatsbyImage)`
  border-radius:${p => p.flexDirection == 'row-reverse' ? '0 0.5rem 0.5rem 0' : '0.5rem 0 0 0.5rem'};
  width:100% !important;
  height:100% !important;
  
  ${media.lessThan("tablet")`
    height:40rem !important;
  `}
`;

const BoxButton = styled.div`
  margin:3rem auto 0 auto;
  text-align:center;
`;

const GlobalTextImage = ({data}) => {
  const { h1, h2, h6, img1Fixed, variable1, pages } = data;

  return (
    <TextImageContainer hasHeadline={h1}>
      <TextImageCard size={1} sm={1} flexDirection={variable1}>
        <Asset>
          <Image image={img1Fixed && img1Fixed.gatsbyImageData} flexDirection={variable1} />
        </Asset>

        <Copy>
          <FadeUp>
            <Headline>{ h1 }</Headline>
            <Subheadline>{ h2 }</Subheadline>

            { pages && 
              <BoxButton>
                <Button to={pages[0].handle}>{h6}</Button>
              </BoxButton>
            }
          </FadeUp>
        </Copy>
      </TextImageCard>
    </TextImageContainer>
  )
}

export default GlobalTextImage
