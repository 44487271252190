import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';

const ProductBelowFold = styled.div`
  background-color:#ffffff;
  text-align:center;
  max-width:${p => p.theme.containerWidthSmall}rem;
  margin:10rem auto 5rem auto;
  ${media.lessThan("tablet")`
    margin:10rem 3rem 5rem 3rem;
  `}
`;

const BelowFoldHeadline = styled(h1)`

`;

const BelowFoldDesecription = styled(body)`
  margin-bottom:1rem;
  ${media.lessThan("tablet")`
    
  `}
`;

class ProductHighlight extends Base {

    render() {
      const { h1, h2 } = this.props.data;
      const Data = this.Data;

        return ( 
          <ProductBelowFold>
            <BelowFoldHeadline><Data label={`h1`}>{ h1 }</Data></BelowFoldHeadline>
            <BelowFoldDesecription>
              <Data label={`h2`}>
                { h2 }
              </Data>
            </BelowFoldDesecription>
          </ProductBelowFold>
        )
    }
}

export default ProductHighlight