import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Container } from 'components/Theme/Grid';
import FadeUp from "components/Theme/FadeUp";
import ReactHtmlParser from 'react-html-parser'

const TextContainer = styled(Container)`
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
`;

const Copy = styled.div`
  padding:8rem 0 6rem 0;
`;

const Superheadline = styled(h1)`
  margin-bottom:1.5rem;
  font-size:5rem;
`;

const Headline = styled(body)`
  font-size:2rem;
  line-height:4rem;
  margin:3rem auto;
  max-width:80%;
  color:#000;
  ${media.lessThan("tablet")`
    
  `};
`;

class GlobalFloatingText2 extends Base {

  render() {
    const { body1, variable1 } = this.props.data;
    const Data = this.Data;

    return (
      <TextContainer style={{ backgroundColor: variable1 === "dark" ? "transparent" : "" }}>
        <FadeUp>
          <Copy>
            <Headline style={{color: variable1 === "dark" ? "#fff" : ""}}><Data label={`h2`}>{ ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }</Data></Headline>
          </Copy>
        </FadeUp>
      </TextContainer>
    )
  }
}



export default GlobalFloatingText2
