import React from "react";
import { Base } from 'components/Blocks';

// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';

// Components
import { GatsbyImage } from "gatsby-plugin-image"


const HeroContainer = styled(Container)`
  margin:3rem 0;
`;


const HeroCard = styled(Card)`
  margin-bottom:0;
  ${media.lessThan("tablet")`
    margin-bottom:3rem;
  `};
  
`;

const HeroAsset = styled(GatsbyImage)`
  border-radius:0.5rem;
  width:100% !important;
  height:30rem;
  ${media.lessThan("tablet")`
    width:100% !important;
  `};
`;


class StoryHero extends Base {

    render() {
        const { img2Fluid, img3Fluid, img4Fluid } = this.props.data;
        const Data = this.Data;

        const img2 = img2Fluid.gatsbyImageData
        const img3 = img3Fluid.gatsbyImageData
        const img4 = img4Fluid.gatsbyImageData

        return ( 
          <HeroContainer>
            <HeroCard neumorphic size={3} sm={1}>
              <Data label={`img2`}><HeroAsset image = { img2 } /></Data>
            </HeroCard>
            <HeroCard neumorphic size={3} sm={1}>
              <Data label={`img3`}><HeroAsset image = { img3 } /></Data>
            </HeroCard>
            <HeroCard neumorphic size={3} sm={1}>
              <Data label={`img4`}><HeroAsset image = { img4 } /></Data>
            </HeroCard>
          </HeroContainer>
        )
    }
}



export default StoryHero