import React, { useState } from "react";
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';

const FadeUp = ({children, className}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const copyAnimation = useSpring({
    opacity: isScrolled ? 1 : 0,
    transform: isScrolled ? "translateY(0px)" : "translateY(50px)"
  });

  return (
    <>
      <Waypoint onEnter={() => setIsScrolled(true) } topOffset={200} />

      <animated.div style={copyAnimation} className={className}>
        { children }
      </animated.div>
    </>
  )
}



export default FadeUp
