import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

import Button from 'components/Theme/Button'
import { GatsbyImage } from "gatsby-plugin-image"

import Variant from 'components/Shopify/Variant';
// import Price from 'components/Theme/Price';

const HeroCard = styled(Card)`
  justify-content:center;
  align-items:center;
  flex-direction:column;
  overflow:hidden;
  position:relative;
  background-position: bottom center;
  height:80vh;

  ${media.lessThan("tablet")`
    justify-content:center;
    align-items:center;
    &::before,
    &::after {
      filter: brightness(0.6) 
    }
  `}
`;


const HeroAsset = styled(GatsbyImage)`
  position:absolute !important;
  width:100%;
  height:100vh;
  z-index:-99;
  object-fit:cover;
  border-radius:1rem;
`;

const HeroCopy = styled.div`
  position:relative;
  display:flex;
  width:90%;
  flex-direction:column;
  justify-content:center;
  min-height:40rem;
  top:0;
  text-align:center;
  align-items:center;
  margin:0 auto;
  ${media.lessThan("tablet")`
    width:100%;
    padding-right:0;
    padding-left:0;
    max-width:40rem;
    text-align:center;
    align-items:center;
  `};
`;

const HeroHeadline = styled(h1)`
  color:#fff;
  text-shadow:0 0rem 1rem #353535;
  margin:0 0 2.5rem 0;
  ${media.greaterThan("tablet")`
    line-height:6.4rem;
  `};
`;

const HeroSubheadline = styled(h2)`
  color:#fff;
`;

const BuyNowButton = styled(Button)`
  color:#000;
`;

const Purchasing = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:2rem;
`;


class GlobalSquareHero extends Base {

  render() {
    const { h1, h2, img1Fluid, shopifyId } = this.props.data;
    const shopify = this.props.shopify;
    const Data = this.Data;

    const heroImage = img1Fluid.gatsbyImageData

    return (
      <Container> 
        <HeroCard neumorphic>
          <Data label={`img1`}>
            <HeroAsset image={heroImage}/>
          </Data>
          <HeroCopy>
            <HeroHeadline study={`h1`}>
              <Data label={`h1`}>{h1}</Data>
            </HeroHeadline>
            <HeroSubheadline study={`h2`}>
              <Data label={`h2`}>{h2}</Data>
            </HeroSubheadline>

            <Purchasing>
              <Variant shopify={shopify} variantId={shopifyId}>
                {variant => (
                  <>
                    <BuyNowButton to={`/products/${variant.product.handle}`}>Buy Now</BuyNowButton>
                  </>
                )}
              </Variant>
            </Purchasing>
          </HeroCopy>
        </HeroCard>
      </Container>
    )
  }
}



export default GlobalSquareHero
