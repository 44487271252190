import React, { Component, Fragment } from 'react'
import styled from 'styled-components';

const TextArea = styled.textarea`
  width:100%;
  height:${p => p.theme.formInputWidth / 4}rem;
  font-size:${p => p.theme.formInputFontSize}rem;
  display:block;
  border:0;
  resize: vertical;
  width:100%;
  font-size:${p => p.textSmall ? p.theme.formInputFontSize / 1.2 : p.theme.formInputFontSize}rem;
  border:1px solid #e8e8e8;
  background: ${p => p.theme.darkWhite};
  text-align: ${p => p.textAlign ? p.textAlign : 'left'};
  color:#000;
  padding:1rem;
  margin-bottom:1rem;
  font-family:Poppins;
  &:focus {
    outline: none;
  }
  
  
`;

class TextAreaField extends Component {
  render() {
    const { name, placeholder, value, required } = this.props

    return (
      <Fragment>
        <TextArea name={name} required={required} value={value} placeholder={placeholder} />
      </Fragment>
    )
  }
}

export default TextAreaField;
