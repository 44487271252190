import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { body, h3 } from 'components/Theme/Copy';
import { Container } from 'components/Theme/Grid';

const InfoBar = styled(Container)`
  display:flex;
  justify-content:space-around;
  background-color:#CDC6BE;
  font-size:1.4rem;
  padding:8rem 0;
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`
const InfoBarItem = styled.div`
  text-align:center;
  width:30rem;
  color:black;
  ${media.lessThan("tablet")`
    padding:3rem;
    width:100%;
  `}
`;

const BenefitsHeadline = styled(h3)`
  margin-bottom:0.5rem;
  color:black;
`;

const BenefitsSubheadline = styled(body)`
  font-size:1.8rem;
  display:block;
  margin:0;
  padding:0;
  color:black;
  

  ${media.greaterThan("desktop")`
    font-size:1.8rem;
  `};
`;

class GlobalBenefits extends Base {

    render() {
        const { h1, h2, h3, h4, h5, h6 } = this.props.data;
        const Data = this.Data;
        
        return ( 
          <InfoBar>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h1`}>{h1}</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h2`}>{h2}</Data></BenefitsSubheadline>
            </InfoBarItem>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h3`}>{h3}</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h4`}>{h4}</Data></BenefitsSubheadline>
            </InfoBarItem>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h5`}>{h5}</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h6`}>{h6}</Data></BenefitsSubheadline>
            </InfoBarItem>
          </InfoBar>
        )
    }
}

export default GlobalBenefits