import React from "react";
import { Base } from '.';

import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';

const DIYContainer = styled(Container)`
  justify-content:center;
  flex-direction: column;
  ${media.lessThan("tablet")`
    padding:0;
  `};
`;

const HeadlineCard = styled(Card)`
  justify-content: center;
  align-items: center;

  ${media.lessThan("tablet")`
    padding:0 1.5rem;
  `};
`;

const AssetCard = styled.div`
  flex-direction: row;
  flex-wrap:wrap;
  display:flex;
  justify-content:space-around;
  ${media.lessThan("tablet")`
    flex-direction: column;
    justify-content:center;
    align-items:center;
  `};
`;

const Copy = styled.div`
  padding:${p => p.theme.boxPadding}rem;

  ${media.lessThan("tablet")`
    padding:2rem;
  `};
`;

const DIYHeadline = styled(h1)`
  margin-bottom:3rem;
  text-align:left;
  color:${p => p.theme.cardTextColor};
  font-size:6rem;
  ${media.lessThan("tablet")`
    margin-top:5rem;
    text-align:center;
  `};
`;


const DIYSubheadline = styled(body)`
  text-align:left;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    max-width:40rem;
    margin:0 auto 5rem auto;
    text-align:center;
  `};
`;

const Image = styled(GatsbyImage)`
  border-radius:0.5rem;
  width:20rem !important;
  height:100%;
  ${media.lessThan("tablet")`
    width:20rem !important;
    margin-top:6rem;
  `};
`;

// As seen in
class HomeAsSeenIn extends Base {

  render() {
    const { h1, h2, img1Fluid, img2Fluid, img3Fluid  } = this.props.data;
    const Data = this.Data;
    const img1 = img1Fluid.gatsbyImageData
    const img2 = img2Fluid.gatsbyImageData
    const img3 = img3Fluid.gatsbyImageData
   
    return (
      <DIYContainer gutterBottom>
        <HeadlineCard fixedHeight size={2} sm={1}>
          <Copy firstCol={true}>
            <DIYHeadline><Data label={`h1`}>{ h1 }</Data></DIYHeadline>
            <DIYSubheadline><Data label={`h2`}>{ h2 }</Data></DIYSubheadline>
          </Copy>
        </HeadlineCard>
        <AssetCard fixedHeight size={2} sm={1}>
          <Data label={`img1`}>
            <Image image={img1} />
          </Data>
        
          <Data label={`img2`}>
            <Image image={img2} />
          </Data>

          <Data label={`img3`}>
            <Image image={img3} />
          </Data>
        </AssetCard>
        
      </DIYContainer>
    )
  }
}

export default HomeAsSeenIn
