import React from "react";
import { Base } from '.';

import ReactHtmlParser from 'react-html-parser'

import { body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import { Container } from 'components/Theme/Grid';


const SummaryContainer = styled(Container)`
  background-color:${p => p.theme.cardBgColorDark};
  display:flex;
  justify-content:center;
  align-items:center;
  padding:8rem;
  ${media.lessThan("tablet")`
    padding:8rem 3rem;
  `}
`;

const Summary = styled(body)`
  color:#fff;
  text-align:center;
  max-width: ${p => p.theme.containerWidthSmall}rem;
`;

class GlobalSimpleDarkBox extends Base {

    render() {
        const { body1 } = this.props.data;
        const Data = this.Data
        
        return ( 
          <SummaryContainer neumorphic gutterBottom>
            <Summary>
              <Data label={`body1`}>
                { ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }
              </Data>
            </Summary>
          </SummaryContainer>
        )
    }
}



export default GlobalSimpleDarkBox