import React from "react";
import { Base } from '.';


import ReactHtmlParser from 'react-html-parser';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';

import { Container } from 'components/Theme/Grid';


const Wrapper = styled(Container)`
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
`;

const WhoContainer = styled.div`
  padding:10rem 0;
  display:flex;
  flex-direction:column;
  text-align:center;
  ${media.lessThan("tablet")`
    padding:10rem 3rem;
  `}
`;

const Headline = styled(h1)`
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    padding:0;
  `}
`;


const Copy = styled(body)`
  max-width: ${p => p.theme.containerWidthSmall}rem;
  color:${p => p.theme.cardTextColor};
`;

class UnderbrickTarget extends Base {

    render() {
        const { h1,
                body1 } = this.props.data;

        const Data = this.Data

        return ( 
          <Wrapper neumorphic gutterBottom>
            <WhoContainer>
              <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
              <Copy><Data label={`body1`}>{ ReactHtmlParser(body1.childMarkdownRemark.html) }</Data></Copy>
            </WhoContainer>
          </Wrapper>
        )
    }
}



export default UnderbrickTarget