import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';
import InlineMailchimpForm from "components/Helpers/InlineMailchimpForm";

const NewsletterContainer = styled(Container)`
  padding:10.5rem 4.5rem;
  display:flex;
  border:0.1rem solid #222;
  justify-content:space-around;
  background-color:${p => p.theme.cardBgColorDark};
  font-size:1.4rem;
  
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:4.5rem 4rem 0 4rem;
  `}
`;

// Carbon Makes it Easy
class GlobalNewsletterSignup extends Base {

  render() {
    const { h1 } = this.props.data;
    const Data = this.Data;
   
    return (
      <NewsletterContainer gutterBottom>
        <Card>
          <Data label={`h1`}>{h1}</Data>
          <InlineMailchimpForm groupName="General->News, Updates, & Recipes">Get in touch</InlineMailchimpForm>
        </Card>
      </NewsletterContainer>
    )
  }
}

export default GlobalNewsletterSignup
