import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container } from 'components/Theme/Grid';

const InfoBar = styled(Container)`
  padding:6.5rem 4.5rem;
  width:100%;
  display:flex;
  justify-content:space-around;
  
  font-size:1.4rem;
  
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:4.5rem 4rem 0 4rem;
  `}

  
`
const InfoBarItem = styled.div`
  color:${p => p.theme.brandPrimary};
  ${p => p.theme.typographyBody};
  text-align:center;
  ${media.lessThan("tablet")`
    padding-bottom:4.5rem;
  `}
`;

const BenefitsHeadline = styled.h3`

`;

const BenefitsSubheadline = styled.div`
  font-size:1.6rem;
`;

class ProductBenefits extends Base {

    render() {
        const { h1, h2, h3, h4, h5, h6 } = this.props.data;
        const Data = this.Data;

        return ( 
          <InfoBar neumorphic gutterBottom>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h1`}>{ h1 }</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h2`}>{ h2 }</Data></BenefitsSubheadline>
            </InfoBarItem>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h3`}>{ h3 }</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h4`}>{ h4 }</Data></BenefitsSubheadline>
            </InfoBarItem>
            <InfoBarItem>
              <BenefitsHeadline><Data label={`h5`}>{ h5 }</Data></BenefitsHeadline>
              <BenefitsSubheadline><Data label={`h6`}>{ h6 }</Data></BenefitsSubheadline>
            </InfoBarItem>
          </InfoBar>
        )
    }
}

export default ProductBenefits