import { getSrc } from './ImageHelper';

export const toPrice = (price) => {
  return '$' + (price && price) || 0
}

export const toFullTitle = (shopifyVariant) => {
  if (shopifyVariant.product) {
    return shopifyVariant.product.title + ' - ' + shopifyVariant.title
  } else {
    return shopifyVariant.title
  }
  
}

export const findFirstImage = (contentfulVariant) => {
  return getSrc(contentfulVariant.images && contentfulVariant.images[0])
}

export const isPreorder = (shopifyVariant) => {
  if (shopifyVariant) {
    if (shopifyVariant.availableForSale && shopifyVariant.inventoryQuantity <= 0 && !isGiftCard(shopifyVariant)) {
      return true
    }
  }

  return false
}

export const isGiftCard = (shopifyVariant) => {
  if (shopifyVariant) {
    if (shopifyVariant.product.handle == 'carbon-gift-card') {
      return true
    }
  }
}

export const affirmRefresh = () => {
  if (typeof window !== `undefined`) {
    window.affirm.ui.refresh()
  }
}

export const affirmPriceFormat = (price) => {
  return price?.replace(".", "")
}
