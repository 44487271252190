import React from "react";
import { Base } from '.';
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { GatsbyImage } from "gatsby-plugin-image"

const Container = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  padding:0;
  position:relative;
  justify-content:space-around;
  ${media.lessThan("tablet")`
    margin:0 auto;
  `};
`;

const Asset = styled.div`
  width:50%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  padding:1.5rem;
  position:relative;
`;

const Image = styled(GatsbyImage)`
  position:relative;
  border-radius:0.5rem;
  width:100%;
  box-shadow:0 0 3.5rem 1rem rgba(0,0,0,0.22);
`;

const Overlay = styled.div`
  position:absolute;
  bottom:1.5rem;
  left:1.5rem;
  z-index:99;
  width:100%;
  padding:1.5rem;
`

class GlobalDualImage extends Base {


  render() {
    const { h1, img1Fluid, img2Fluid, body1, body2 } = this.props.data;
    const Data = this.Data;
    const imageLeft = img1Fluid.gatsbyImageData
    const imageRight = img2Fluid.gatsbyImageData
   
    return (
      <Container hasHeadline={h1}>
        <Asset>
        <Data label={`img1`}>
          <Image image={imageLeft} />
          <Overlay><Data>{ ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }</Data></Overlay>
        </Data>
        </Asset>
        <Asset>
        <Data label={`img2`}>
          <Image image={imageRight} />
          <Overlay><Data>{ ReactHtmlParser(body2 && body2.childMarkdownRemark.html) }</Data></Overlay>
        </Data>
        </Asset>
      </Container>
    )
  }
}

export default GlobalDualImage
