import React from 'react'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { body } from 'components/Theme/Copy';
import { isPreorder, isGiftCard } from 'components/Helpers/ProductShopHelper';

const Container = styled.div`
  margin-top:1.5rem;
`;

const Headline = styled(body)`
  margin:0;
  font-size:1.6rem;
  cursor:pointer;
  user-select: none;
  color:${p => p.theme.black};
`;


const EstimatedDelivery = ({ variant, oos, className }) => {
  let headline;
  // Is the product available for preorders?
  const preorder = isPreorder(variant)

  // get date
  // get type of product
  // set date to 4/5 weeks in future 
  // return shipping month
  if (preorder) {
    let today = new Date()
    let month = today.getMonth()
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    let monthName = monthNames[month + 1]
    if (month == 11) {
      monthName = monthNames[0]
    }

    headline = `Shipping in ${monthName}`
  } else if (!preorder && !oos && !isGiftCard(variant)) {
    headline = "Shipping in 1-2 days"
  } else  {
    headline = ""
  }


  return (
    <Container className={className}>
      <Headline>{headline}</Headline>
    </Container>
  )
}


export default EstimatedDelivery

