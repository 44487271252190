import React from "react";
import { Base } from '.';

import ReactHtmlParser from 'react-html-parser';

import { h1, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

const Headline = styled(h1)`
  font-size:3.5rem;
  color:${p => p.theme.cardTextColor};
  margin-bottom:3rem;
  ${media.lessThan("tablet")`
    padding:0;
  `}
`;

const Subheadline = styled(body)`
  color:${p => p.theme.cardTextColor};
  font-size:1.8rem;
  line-height:3rem;

  p {
    margin-bottom:1.5rem;
  }
`;

const Container = styled.div`
  padding:0 10rem;
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content:center;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    padding:0;
    text-align:center;
  `}
`;

class SupportWelcome extends Base {

    render() {
        const { h1, body1 } = this.props.data;

        const Data = this.Data

        return ( 
          <Container>
            <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
            <Subheadline>
              <Data label={`body1`}>{ ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }</Data>
            </Subheadline>
          </Container>
        )
    }
}



export default SupportWelcome;