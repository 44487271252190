import React, { useState } from "react";
import { Base } from '.';

import { body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import FormTag from 'components/Page/Forms/Form';
import TextField from 'components/Page/Forms/TextField';
import TextAreaField from 'components/Page/Forms/TextAreaField';
import Button from 'components/Theme/Button';

const Container = styled.section`
  padding: 10rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
  margin-top: 5rem;
  ${media.lessThan("tablet")`
    padding: 3rem 0 3rem 0;
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Col = styled.div`
  width: 100%;
  padding-right: ${p => p.first ? '1rem' : 'none'};
`;

const InnerFormContainer = styled.div`
  margin-top: 2rem;
  width: 50rem;
  input {
    font-size: 2rem;
  }
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.6rem;
  color: #000;
  input {
    margin-right: 0.5rem;
  }
`;

class GiveawayForm extends Base {
  constructor(props) {
    super(props);
    this.state = {
      agreed: false, // Checkbox initial state
    };
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      agreed: !prevState.agreed, // Toggle checkbox state
    }));
  };

  render() {
    return (
      <Container className={this.props.className}>
        <FormTag name="PizzaExpoGiveaway" successPage="/giveaway-signup">
          <InnerFormContainer>
            <Row>
              <TextField
                type="text"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
                autocapitalize="none"
                name="name"
                required="required"
                placeholder="Name"
              />
            </Row>
            <Row>
              <TextField
                type="text"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
                autocapitalize="none"
                name="email"
                required="required"
                placeholder="Email"
              />
            </Row>
            <CheckboxLabel>
              <input
                type="checkbox"
                checked={this.state.agreed}
                onChange={this.handleCheckboxChange}
                required
              />
              I opt in to marketing emails
            </CheckboxLabel>
            <Controls>
              <Button disabled={!this.state.agreed}>Sign me up!</Button>
            </Controls>
          </InnerFormContainer>
        </FormTag>
      </Container>
    );
  }
}

export default GiveawayForm;
