import React, { Component } from 'react';
import { graphql } from 'gatsby'
import styled from 'styled-components';

class Block extends Component {

  render() {
    const { blockId } = this.props.data;

    if (blockId) {
      const shopify = this.props.shopify;
      const BlockComponent = require(`components/Blocks/${blockId}`).default;
      return <BlockComponent frontmatter={this.props.frontmatter} data={this.props.data} shopify={shopify} />
    }
  }
}

// Base Block Component (inheritance)
//const studyLabel = styled

const Study = styled.div`
  ${p => p.label && `
    display:contents;
    
    :after {
      font-size:1.5rem;
      font-family:sans-serif;
      text-transform:none;
      font-weight:normal;
      display:block;
      position:relative;
      margin:0 auto;
      text-align:center;
      letter-spacing:normal;
      line-height:normal;
      background-color:red;
      color:white;
      padding:0.25rem 0.5rem;
      content:"${p.label}";
      z-index:1;
    }
    
  `}
`;

export class Base extends Component {  

  Data = (props) => {
    if (this.props.frontmatter && this.props.frontmatter.study) {
      return <Study label={props.label}>{props.children}</Study>
    }

    return props.children
  }
}

export class Blocks extends Component {
  render() {
    const blocks = this.props.data;
    const shopify = this.props.shopify;

    return (
      <>
        {blocks && blocks.map((block, i) => (
          <Block data={block} shopify={shopify} key={`block_${i}`} />
        ))}
      </>
    )
  }
}


export default Block

export const blockFragment = graphql`
  fragment BlockFields on ContentfulBlock {
    __typename

    id
    blockId
    shopifyId
    contentful_id
    variable1
    h1
    h2
    h3
    h4
    h5
    h6
    body1 { 
      childMarkdownRemark {
        html
      }
    }
    body2 { 
      childMarkdownRemark {
        html
      }
    }
    body3 { 
      childMarkdownRemark {
        html
      }
    }
    body4 { 
      childMarkdownRemark {
        html
      }
    }
    body5 { 
      childMarkdownRemark {
        html
      }
    }
    img1Fluid: img1 {
       gatsbyImageData(layout: FULL_WIDTH)
    }
    img1Fixed: img1 {
      gatsbyImageData
    }
    img1Fixed500: img1 {
      gatsbyImageData(width: 500)
    }
    img2Fluid: img2 {
       gatsbyImageData(layout: FULL_WIDTH)
    }
    img2Fixed: img2 {
      gatsbyImageData
    }
    img3Fluid: img3 {
       gatsbyImageData(layout: FULL_WIDTH)
    }
    img3Fixed: img3 {
      gatsbyImageData
    }
    img4Fluid: img4 {
       gatsbyImageData(layout: FULL_WIDTH)
    }
    img4Fixed: img4 {
      gatsbyImageData
    }
    vid1 {
      file {
        url
      }
    }
    imageGalleryFluid: imageGallery {
      gatsbyImageData(layout: FULL_WIDTH)
    }
    imageGalleryFixed: imageGallery {
      gatsbyImageData
    }
    imageGalleryFixed100: imageGallery {
      gatsbyImageData(width: 100)
    }
    imageGalleryFixed500: imageGallery {
      gatsbyImageData(width: 500)
    }
    pages {
      handle
      seo {
        pageTitle
      }
    }
  }
`;