import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser'

import { Container, Card  } from 'components/Theme/Grid';

const PolicyContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

const PolicyCard = styled(Card)`
  max-width: ${p => p.theme.containerWidthSmall}rem;
  flex-direction:column;
  font-size:1.6rem;
  padding:2rem 6rem;
  margin-top:6rem;
  background-color:${p => p.theme.cardBgColor};
  color:${p => p.theme.cardTextColor};
  p {
    padding:1.6rem 0;
  }
  h3 {
    font-size:1.8rem;
    margin-top:4rem;
  }
`;


class PolicyContent extends Base {

    render() {
        const { body1 } = this.props.data;
        const Data = this.Data;

        return ( 
          <PolicyContainer>
            <PolicyCard neumorphic>
            <Data label={`body1`}>
              { ReactHtmlParser(body1 && body1.childMarkdownRemark.html) }
            </Data>
            </PolicyCard>
          </PolicyContainer>
        )
    }
}



export default PolicyContent