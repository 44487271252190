import React, { useState } from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h3 } from 'components/Theme/Copy';
import { Container, CardStyle  } from 'components/Theme/Grid';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';

import { GatsbyImage } from "gatsby-plugin-image"
import Variant from 'components/Shopify/Variant';
import Button from 'components/Theme/Button'

const ProductsContainer = styled(Container)`
  
  flex-direction:column;
  background-color:${p => p.theme.white};
  padding:${p => p.theme.boxPadding}rem;
  ${media.lessThan("tablet")`
    flex-wrap:nowrap;
  `}
`;

const ProductAccessoryContainer = styled(Container)`
  flex-direction:${p => p.flexDirection || 'row'};
  height:auto;
  ${media.lessThan("tablet")`
    flex-direction:row;
  `}
`;


const BlockHeadline = styled(h3)`
  width:100%;
  text-align:center;
  font-weight:bold;
  margin-bottom:3rem;
  color:${p => p.theme.black};
`

const ProductsCard = styled(animated.div)`
  ${CardStyle};
  flex-direction:column;
  align-items:center;
  justify-content: center;
  position:relative;
  
  ${media.lessThan("tablet")`
    flex-direction:row;
    margin-bottom:0;
  `}
`;

const FeaturesCard = styled(ProductsCard)`
  
`;


const ProductWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
  padding:5rem 0;
  width:100%;
  ${media.lessThan("tablet")`
    width:100%;
    height:100%;
    display:flex;
    
    justify-content:center;
  `}
`;

const ProductHeadline = styled(h1)`
  margin-bottom:1.5rem;
  color:#000;
  ${media.lessThan("tablet")`
    font-size:5rem;
    margin-bottom:0;
  `};
`;

const ProductSubheadline = styled(h3)`
  margin-bottom:3rem;
  color:#000;
  ${media.lessThan("tablet")`
    
  `};
`;

const ProductImage = styled(GatsbyImage)`
  
  width:100%;
  z-index:-99;
  object-fit:contain;
`;

const ProductDetails = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  ${media.lessThan("tablet")`
    width:100%;
  `}
`;

const Copy = styled.div`
  display:flex;
  text-align:center;
  flex-direction:column;
  justify-content: center;
  align-items:center;
`;


const ProductCard = ({data, shopify}) => {
  const { h1, h2, img1Fixed, variable1, shopifyId } = data;
  const [isScrolled, setIsScrolled] = useState(false);

  const animationLeft = useSpring({
    opacity: isScrolled ? 1 : 0,
    transform: isScrolled ? "translateX(0px)" : "translateX(-50px)"
  });

  const animationRight = useSpring({
    opacity: isScrolled ? 1 : 0,
    transform: isScrolled ? "translateX(0px)" : "translateX(50px)"
  });

  const img1 = img1Fixed.gatsbyImageData

  return (
    <>
      <Waypoint onEnter={() => setIsScrolled(true) } />
      
      <ProductsContainer>
        <ProductAccessoryContainer fixedHeight={true} flexDirection={variable1}>
          <ProductsCard  size={2} sm={1} style={animationLeft}>
            <ProductImage image={img1} />
          </ProductsCard>

          <FeaturesCard  size={2} sm={1} style={animationRight}>
            <Variant variantId={shopifyId} shopify={shopify}>
                {variant => (
                  <ProductWrapper>
                    <ProductHeadline>{h1}</ProductHeadline>
                    <ProductSubheadline>{h2}</ProductSubheadline>
                    <ProductDetails>
                      <Copy>
                        <Button to={'/products/' + variant.product.handle}>Learn More</Button>
                      </Copy>
                    </ProductDetails>
                  </ProductWrapper>
                )}
            </Variant>
          </FeaturesCard>
        </ProductAccessoryContainer>
      </ProductsContainer>
    </>
  )
}

export default ProductCard
