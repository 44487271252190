import React from "react";
import { Base } from 'components/Blocks';

// Helpers
import ReactHtmlParser from 'react-html-parser'

// Styles
import { h4, body  } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';


// Components
import YouTube from 'react-youtube';


const BodyContainer = styled(Container)`
  padding:12rem;
  background-color:${p => p.theme.cardBgColor};
  ${media.lessThan("tablet")`
    padding:12rem 4rem;
  `};
`;

const Headline = styled(h4)`
  font-size:3rem;
  text-transform: uppercase;
  margin-bottom:2rem;
`;

const BodyCard = styled(Card)`
  flex-direction:column;

  ${media.lessThan("tablet")`
    align-items:center;
    text-align:center;
  `};
`;

const Copy = styled(body)`
  color:${p => p.theme.cardTextColor};
  margin:2rem 0 0 0;
  font-size:2.6rem;
`;

const YouTubeWrapper = styled.div`
  
  .youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;
  }

  .youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

class StoryBody extends Base {

    _onReady = (e) => {
      // access to player in all event handlers via event.target
      e.target.pauseVideo();
    }

    render() {
        const { body3, body4, body5} = this.props.data;
        const Data = this.Data;

        return ( 
          <BodyContainer neumorphic>
            <BodyCard>
              <Headline>About us</Headline>

              <Copy>
                <Data label={`body5`}>{ ReactHtmlParser(body5 && body5.childMarkdownRemark.html) }</Data>
              </Copy>

              <Copy>
                <Data label={`body3`}>{ ReactHtmlParser(body3 && body3.childMarkdownRemark.html) }</Data>
              </Copy>

            </BodyCard>
          </BodyContainer>
        )
    }
}

export default StoryBody