import React from "react";
import { Base } from '.';

import { h1, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

const Container = styled.div`
  border-top:0.1rem solid ${p => p.theme.brandPrimaryTextColor};
  margin-top:5rem;
  padding-top:5rem;
`;

const InnerContainer = styled.div`
  padding:2.5rem 10rem;
  ${media.lessThan("tablet")`
    padding:0;
  `};
`;

const Headline = styled(h1)`
  color: ${p => p.theme.cardTextColor};
  font-size:3rem;
  margin:0;
`;

const Subheadline = styled(body)`
  color: ${p => p.theme.cardTextColor};
  font-size:1.8rem;
  margin:0;
`;

class SupportFormHeader extends Base {
  render() {
    const { h1, h2 } = this.props.data;
    const Data = this.Data

    return ( 
      <Container>
        <InnerContainer>
          <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
          <Subheadline><Data label={`h2`}>{ h2 }</Data></Subheadline>
        </InnerContainer>
      </Container>
    )
  }
}



export default SupportFormHeader;