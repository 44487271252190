import React from "react";
import Block from 'components/Blocks';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';


const HeroContainer = styled.div`
  height:40rem;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  ${media.lessThan("tablet")`
    height:30rem;
    padding:0 2rem;
  `};
`;

const HeroCopy = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align:center;
  max-width:70rem;
`;

const HeroHeadline = styled(h1)`
  margin-bottom:2.5rem;
  color:#000;
`;

const HeroSubheadline = styled(body)`
  color:#000;
  ${media.lessThan("tablet")`
    padding:0 3rem;
  `};
`;


class AccessoriesHero extends Block {

  render() {
    const { h1, h2 } = this.props.data;
    const Data = this.Data;

    return (
      <HeroContainer> 
        <HeroCopy>
          <HeroHeadline><Data label={`h1`}>{h1}</Data></HeroHeadline>
          <HeroSubheadline><Data label={`h2`}>{h2}</Data></HeroSubheadline>
        </HeroCopy>
      </HeroContainer>
    )
  }
}



export default AccessoriesHero
