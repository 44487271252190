import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import { h1, h4, h5, body } from 'components/Theme/Copy';
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Card } from 'components/Theme/Grid';

const EasyContainer = styled(Container)`
  flex-direction:row;
  flex-wrap:wrap;
`;

const DefaultCard = styled(Card)`
  flex-direction:column;
  justify-content:center;
  align-items:center;
  height:40rem;

  ${media.greaterThan("desktop")`
    height:60rem;
  `}
`;

const CopyCard = styled(DefaultCard)`
  background-color:${p => p.theme.cardBgColor};
`;

const Copy = styled.div`
  padding:${p => p.theme.boxPadding}rem;
  height:40rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Headline = styled(h4)`
  text-align:center;
  background-color:transparent !important;
  max-width:${p => p.theme.containerWidthSmall}rem;
  color:${p => p.theme.cardTextColor};
  margin-bottom:1.5rem;
`;

const Subheadline = styled(h5)`
  color:${p => p.theme.cardTextColor};
  font-size:2rem;
  text-align:center;
`;

const Image = styled(GatsbyImage)`
  border-radius:0.5rem;
  width:100% !important;
  height:100% !important;

  ${media.lessThan("tablet")`
    height:40rem !important;
  `}
`;

class GlobalDualImageSplitCopy extends Base {
  state = {
    animateAsset: false
  }

  animateAssets = () => {
    this.setState({animateAsset: true})
  }

  render() {
    const { h1, h2, h3, h4, img1Fixed, img2Fixed } = this.props.data;
    const Data = this.Data;
    
   
    return (
      <EasyContainer hasHeadline={h1}>
        <CopyCard neumorphic size={2} sm={1} fixedHeight>
          <Copy>
            <Headline><Data label={`h1`}>{ h1 }</Data></Headline>
            <Subheadline><Data label={`h2`}>{ h2 }</Data></Subheadline>
          </Copy>
        </CopyCard>
        
        <DefaultCard neumorphic size={2} sm={1} fixedHeight>
          <Data label={`img1`}>
            <Image image={img1Fixed && img1Fixed.gatsbyImageData} />
          </Data>
        </DefaultCard>
      
        <DefaultCard neumorphic size={2} sm={1} fixedHeight>
          <Data label={`img2`}>
            <Image image={img2Fixed && img2Fixed.gatsbyImageData}  />
          </Data>
        </DefaultCard>

        <CopyCard  neumorphic size={2} sm={1} fixedHeight>
          <Copy>
            <Headline><Data label={`h3`}>{ h3 }</Data></Headline>
            <Subheadline><Data label={`h4`}>{ h4 }</Data></Subheadline>
          </Copy>
        </CopyCard>
      
      </EasyContainer>
    )
  }
}

export default GlobalDualImageSplitCopy
