import React, { Component } from 'react';
import styled from 'styled-components';
import Validator from 'lib/validator';
import objectFitVideos from 'object-fit-videos';

const BackgroundVideoContainer = styled.div`
  left: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  display: block;
`;

const BackgroundVideo = styled.video`
  left: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  display: block;
`;

class Video extends Component {

  UNSAFE_componentWillMount () {
    if (typeof window !== `undefined`) {
      objectFitVideos();
    }
  }

  isVideo(src) {
    return src && Validator.isVideo(src)
  }

  render() {
    const { src } = this.props;

    return (
      <BackgroundVideoContainer className={this.props.className}>
      { this.isVideo(src) &&
        <BackgroundVideo loop autoPlay playsInline muted data-label={this.props['data-label']} >
          <source src={src} type="video/mp4"/>
        </BackgroundVideo> }
      </BackgroundVideoContainer>
    );
  }
}

export default Video;
