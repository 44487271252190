import React from "react";
import { Base } from '.';

import ReactHtmlParser from 'react-html-parser'

import { h1, h2, body } from 'components/Theme/Copy';
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container } from 'components/Theme/Grid';

const IllustrationContainer = styled(Container)`
  background-color:${p => p.theme.cardBgColor};
  padding:10rem;
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content:center;
  ${media.lessThan("tablet")`
    padding:10rem 4rem;
  `}
`;

const Headline = styled(h1)`
  text-align:center;
  ${media.lessThan("tablet")`
    padding:0;
  `}
`;

const Subheadline = styled(h2)`
  color:${p => p.theme.brandPrimary};
  text-shadow:none;
  font-size:1.5rem;
  ${media.lessThan("tablet")`
    padding:0;
  `}
`;

const Row = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-top:5rem;
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const Col = styled.div`
  width:48%;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  ${media.lessThan("tablet")`
    width:100%;
    ${p => p.second ? `
      margin-top:5rem;
    ` : null }
  `}
`;

const Copy = styled(body)`

`;

class UnderbrickIllustrations extends Base {

    render() {
        const { h1, 
                h2,
                h3,
                body1,
                body2 } = this.props.data;

        const Data = this.Data

        return ( 
          <IllustrationContainer neumorphic gutterBottom>

            <Headline><Data label={`h1`}>{h1}</Data></Headline>

            <Row>
              <Col>
                <Subheadline><Data label={`h2`}>{h2}</Data></Subheadline>
                <Copy><Data label={`body1`}>{ ReactHtmlParser(body1.childMarkdownRemark.html) }</Data></Copy>
              </Col>

              <Col second>
                <Subheadline><Data label={`h3`}>{h3}</Data></Subheadline>
                <Copy><Data label={`body2`}>{ ReactHtmlParser(body2.childMarkdownRemark.html) }</Data></Copy>
              </Col>
            </Row>
          </IllustrationContainer>
        )
    }
}



export default UnderbrickIllustrations