import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2, h3 } from 'components/Theme/Copy';
import FadeUp from "components/Theme/FadeUp";

import Button from 'components/Theme/Button'
import Video from 'components/Theme/Video'; 

import Variant from 'components/Shopify/Variant';
import Price from 'components/Theme/Price';

import { Container } from 'components/Theme/Grid';


const HeroContainer = styled(Container)`
  height:60rem;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  overflow:hidden;
  position:relative;
  background-position: bottom center;
  ${media.lessThan("tablet")`
    justify-content:center;
    align-items:center;
    &::before,
    &::after {
      filter: brightness(0.6) 
    }
  `}
`;

const HeroVideo = styled(Video)`
  
`;

const HeroCopy = styled.div`
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  min-height:40rem;
  top:0;
  text-align:center;
  align-items:center;
  margin:0 auto;
  
  ${media.lessThan("tablet")`
    width:80%;
    padding-right:0;
    padding-left:0;
    max-width:40rem;
  `};
`;

const HeroHeadline = styled(h1)`
  color:#fff;
  text-shadow:0 0.1rem 3rem #39280B;
  margin:0 0 2.5rem 0;
  font-size:8rem;
  ${media.lessThan("tablet")`
    
  `};
  ${media.greaterThan("tablet")`
    
  `};
`;

const HeroSubheadline = styled(h3)`
  color:#fff;
  max-width:60rem;
  margin:0 auto;
  text-align:center;
  font-size:2rem;
`;

const BuyNowButton = styled(Button)`
  margin-top:0.5rem;
`;

const Purchasing = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:2rem;
`;

const PriceLabel = styled(Price)`
  color:#fff;
  margin-top:1rem;
  line-height:3.5rem;
  font-size:1.85rem;
  ${media.lessThan("tablet")`
    font-size:1.5rem;
  `};
`;

class HomeVideoHero extends Base {

  render() {
    const { shopifyId, h1, h2, vid1 } = this.props.data;
    const shopify = this.props.shopify;
    const Data = this.Data;
    
  
    return (
      <HeroContainer neumorphic> 
        <Data label={`vid1`}>
          { vid1 && 
            <HeroVideo src={vid1.file.url}/>
          }
        </Data>
        <HeroCopy>
          
            <HeroHeadline study={`h1`}>
              <Data label={`h1`}>{h1}</Data>
            </HeroHeadline>
            <HeroSubheadline study={`h2`}>
              <Data label={`h2`}>{h2}</Data>
            </HeroSubheadline>
            <Purchasing>
              
              { shopifyId && 
                <Variant shopify={shopify} variantId={shopifyId}>
                  {variant => (
                    <>
                      <BuyNowButton to={`/products/${variant.product.handle}`}>Learn More</BuyNowButton>
                    </>
                  )}
                </Variant>
              }
              
              
            </Purchasing>
          
        </HeroCopy>
      </HeroContainer>
    )
  }
}



export default HomeVideoHero
