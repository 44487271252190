import React from "react";
import { Base } from '.';

import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, body } from 'components/Theme/Copy';
import { Container, Card  } from 'components/Theme/Grid';


class GlobalYouTube extends Base {

  render() {
    const { h1, h2, img1Fluid, img2Fluid, img3Fluid, img4Fluid } = this.props.data;
    const Data = this.Data;
   
    return (
      <Container>
       
      </Container>
    )
  }
}

export default GlobalYouTube
