import React from "react";
import { Base } from 'components/Blocks';

// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Container, Card } from 'components/Theme/Grid';

// Components
import { GatsbyImage } from "gatsby-plugin-image"


const Refersion = styled.div`
  width:100%;
  overflow:auto;

  iframe {
    height:90rem;
  }

  ${media.lessThan("tablet")`
    iframe {
      height:125rem;
    } 
  `};
`;

class RefersionSignupForm extends Base {

  constructor(props) {
    super(props)
    if (typeof window !== `undefined`) {
      const script = document.createElement("script");

      script.src = "https://cdn.refersion.com/iframe.js";
      script.async = true;

      document.body.appendChild(script);
    }
  }

  render() {
    const { img2Fluid, img3Fluid, img4Fluid } = this.props.data;
    const Data = this.Data;

    return ( 
      <Refersion id="refersion-iframe-goes-here" data-frame-url="https://carbon.refersion.com/affiliate/registration"></Refersion>
    )
  }
}



export default RefersionSignupForm