import React from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2 } from 'components/Theme/Copy';
import { Container, Card } from 'components/Theme/Grid';
import FadeUp from "components/Theme/FadeUp";

import Button from 'components/Theme/Button'
import { GatsbyImage } from "gatsby-plugin-image"

const HeroCard = styled(Card)`
  justify-content:center;
  align-items:flex-start;
  flex-direction:column;
  overflow:hidden;
  position:relative;
  background-position: bottom center;

  ${media.lessThan("tablet")`
    justify-content:center;
    align-items:center;
    padding:0 1.5rem;
    &::before,
    &::after {
      filter: brightness(0.6) 
    }
  `}
`;


const HeroAsset = styled(GatsbyImage)`
  position:absolute !important;
  width:100%;
  height:100%;
  z-index:-99;
  object-fit:cover;
  border-radius:0.5rem;
`;

const HeroCopy = styled.div`
  position:relative;
  display:flex;
  width:50%;
  padding:${p => p.theme.boxPadding}rem;
  flex-direction:column;
  justify-content:center;
  min-height:40rem;
  top:0;
  text-align:left;
  align-items:flex-start;
  ${media.lessThan("tablet")`
    width:80%;
    padding-right:0;
    padding-left:0;
    max-width:40rem;
    margin-left:0;
    text-align:center;
    align-items:center;
  `};
`;

const HeroHeadline = styled(h1)`
  color:#fff;
  text-shadow:0 0rem 1rem #353535;
  margin-bottom:3rem;

  ${media.lessThan("tablet")`
    font-size:5rem;
    line-height:5rem;
  `};

  ${media.greaterThan("tablet")`
    line-height:6.4rem;
  `};
`;

const HeroSubheadline = styled(h2)`
  color:#fff;
  margin-bottom:3rem;
`;

const LearnMoreButton = styled(Button)`
  
`;

const GlobalAssetBox = ({data}) => {
  const { h1, h2, img1Fluid } = data;
  const heroImage = img1Fluid.gatsbyImageData

  return (
    <Container fixedHeight> 
      <HeroCard neumorphic>
        <HeroAsset image={heroImage}/>
        
        <HeroCopy>
          <FadeUp>
            <HeroHeadline study={`h1`}>{h1}</HeroHeadline>
            <HeroSubheadline study={`h2`}>{h2}</HeroSubheadline>

            <LearnMoreButton to={"/underbrick-burner"}>Learn More</LearnMoreButton>
          </FadeUp>
        </HeroCopy>
      </HeroCard>
    </Container>
  )
}



export default GlobalAssetBox
