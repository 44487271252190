import React, { useState } from "react";
import { Base } from '.';

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h3, body } from 'components/Theme/Copy';
import { Container, CardStyle  } from 'components/Theme/Grid';

import { GatsbyImage } from "gatsby-plugin-image"
import Variant from 'components/Shopify/Variant';
import Button from 'components/Theme/Button';

// Shopify Components
import EstimatedDelivery from 'components/Page/Product/EstimatedDelivery';

const ProductsContainer = styled(Container)`
  margin-bottom:${p => p.bottomMargin ? '6rem' : 0 };
  background-color:${p => p.theme.white};
`;

const ProductsCard = styled.div`
  ${CardStyle};
  background-color:${p => p.theme.white};
  padding:1.5rem 6rem 1.5rem 3rem;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  position:relative;
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:6rem 0;
  `};
`;

const ProductHeadline = styled(body)`
  color:${p => p.theme.black};
  font-size:2.6rem;
`;

const ProductDetails = styled.div`
  width:calc(100% - 60rem);
  ${media.lessThan("tablet")`
    width:100%;
    text-align:center;
  `};
`;

const AddToCart = styled.div`
  width:30rem;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: flex-end;
  ${media.lessThan("tablet")`
    width:100%;
    padding:3rem 0 1.5rem 0;
  `};
`;

const ProductPrice = styled(body)`
  font-size:1.8rem;
  color:${p => p.theme.black};
`;

const ProductImageWrapper = styled.div`
  width:30rem;
`;

const ProductImage = styled(GatsbyImage)`
  width:25rem;
  ${media.lessThan("tablet")`
    width:auto;
  `};
`;

const DeliveryDate = styled(EstimatedDelivery)`
  font-size:1.6rem;
`;

const ProductAccessoryUpsell = ({data, shopify}) => {
  const { img1Fluid, shopifyId, variable1 } = data;
  const img1 = img1Fluid.gatsbyImageData;

  
  return (
    <ProductsContainer bottomMargin={variable1 == "gutterBottom" ? true : false}>
      <ProductsCard>
        <ProductImageWrapper>
          <ProductImage image={img1} />
        </ProductImageWrapper>

        <Variant variantId={shopifyId} shopify={shopify}>
          {variant => (
            <ProductDetails>
              <ProductHeadline>{variant.product.title}</ProductHeadline>
              <ProductPrice>${variant.price}</ProductPrice>
            </ProductDetails>
          )}
        </Variant>    

        <AddToCart>
          <Variant variantId={shopifyId} shopify={shopify}>
            {variant => (
              <>
                <Button to={'/products/' + variant.product.handle}>Shop</Button>
                <DeliveryDate variant={variant} oos={true} />
              </>
            )}
          </Variant>
          
        </AddToCart>

      </ProductsCard>
    </ProductsContainer>
  )
}

export default ProductAccessoryUpsell
