import React from "react";
import styled from 'styled-components';

const Outlined = styled.span`
color: black;
-webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color: white;
`;


// Split Outline Heading
export const SplitOutlineHeading = ({copy}) => {
  const split = copy.split(' ')
  const [head, ...rest] = split; 

  return (
    <div>
      {head} <Outlined>{rest}</Outlined>
    </div>
  )
}

export default SplitOutlineHeading;
