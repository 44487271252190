import React, { useState, useEffect, useContext } from "react";

// Styles
import styled from 'styled-components';
import { h2, body } from 'components/Theme/Copy';

// Helpers
import _ from 'lodash';
import processHtml from 'lib/processHTML'
import ReactHtmlParser from 'react-html-parser'

// 3rd Party
import { MdKeyboardArrowRight } from "react-icons/md";

const SpecContainer = styled.div`
  margin:4rem 0;
`;

const Specs = styled(body)`
  font-size:1.5rem;
  
  border-top:0.1rem solid ${p => p.theme.cardBorderColor};
  color:${p => p.theme.black};

  &:last-child {
    border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};
  }

  ul {
    margin-left:3rem;
    list-style-type:disc !important;

    li {
      line-height:3rem;
    }
  }
`;

const SpecHeader = styled(h2)`
  margin:0;
  font-size:1.55rem;
  cursor:pointer;
  user-select: none;
`;

const SpecHeaderArrow = styled(MdKeyboardArrowRight)`
  font-size:2rem;
  position:relative;
  top:0.5rem;
  margin-top:-0.5rem;
  transition: transform 0.1s linear;
  transform: ${p => p.showSpecs ? `rotate(90deg)` : `rotate(0deg)`};
`;

const SpecDetails = styled.div`
  display:${p => p.showSpecs ? 'block' : 'none'};
  padding-bottom:1rem;
`;


const SpecsAndFeatures = ({specs, features}) => {
  // State
  const [showSpecs, toggleSpecs] = useState(false);
  const [showFeatures, toggleFeatures] = useState(false);
  
  
  return (
    <SpecContainer>
      { features && 
        <Specs>
          <SpecHeader onClick={ () => toggleFeatures(!showFeatures)}><SpecHeaderArrow showSpecs={showFeatures} /> Features</SpecHeader>
          <SpecDetails showSpecs={showFeatures}>{  ReactHtmlParser((features.childMarkdownRemark.html), { transform: processHtml }) }</SpecDetails>
        </Specs>
      }

      { specs && 
        <Specs>
          <SpecHeader onClick={ () => toggleSpecs(!showSpecs)}><SpecHeaderArrow showSpecs={showSpecs} /> Specs</SpecHeader>
          <SpecDetails showSpecs={showSpecs}>{  ReactHtmlParser((specs.childMarkdownRemark.html), { transform: processHtml }) }</SpecDetails>
        </Specs>
      }
    </SpecContainer>
  )
}



export default SpecsAndFeatures;
